const dataProj = [
  {
    // Data Analysis
    title: "Deep learning Image Classifier",
    date: "2024",
    goal: "Classify landscape's images using a convolutive neural network",
    tools: ["Python", "Tensorflow", "Keras", "Google Colab"],
    image: "classifier.png",
    category: "Data Analysis",
    details: [
      "Scrapping of images to constitute a database",
      "Creation and optimization of a convolutive neural network",
      "Evaluation of the classifier using several factors such as global accuracy, recall or F1-score",
    ],
  },
  {
    title: "Analysis of Factors Affecting Mental Health",
    date: "2024",
    goal: "Analyze factors influencing mental health using Power BI and Python",
    tools: ["Power BI", "Python"],
    image: "mental_health.png",
    category: "Data Analysis",
    details: [
      "Data collection and transformation (missing data, normalization, creation of new measures)",
      "Exploratory data analysis (EDA)",
      "Clustering with Python. Use of the elbow method. Identification of distinct groups and analysis of their characteristics",
      "Creation of a Power BI dashboard for visualization",
    ],
  },
  {
    title: "Ebay Auction Data Analysis",
    date: "2024",
    goal: "Design a database schema for ebay auction data, convert XML files to CSV, and populate a MySQL database",
    tools: ["Java", "MySQL", "Bash", "Docker"],
    image: "ebay_analysis.png",
    category: "Data Analysis",
    details: [
      "Design of a relational schema for ebay XML auction data",
      "Java program to convert XML files into CSV using SAX parsing and eliminating duplicates",
      "SQL scripts to generate tables and load CSV data into MySQL, Bash shell script to automate the process",
      "Testing using SQL queries to ensure data accuracy and retrieve specific information",
    ],
  },
  {
    title: "Study of the topology of a satellite network",
    date: "2024",
    goal: "Analyze the topology of a satellite network with varying densities and distances",
    tools: ["Julia"],
    image: "graphe.png",
    category: "Data Analysis",
    details: [
      "Generation of graphs and plots to visualize the network from coordinates from a csv file",
      "Study of the distribution of degrees and clustering coefficients",
      "Identification of cliques in the swarm graphs",
    ],
  },
  {
    title: "Project on speech recognition",
    date: "2021-2022",
    goal: "Improve speech recognition for people with hearing deficiency thanks to linguistic models",
    tools: ["Python"],
    image: "tipe.png",
    category: "Data Analysis",
    details: [
      "Comparison of the efficiency of several algorithms using the bigram method",
      "Optimization for the use of hearing impaired people",
    ],
  },
];

export default dataProj;
